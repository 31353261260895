<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="false"
      :delet="false"
      :pages="pages"
      :button-show="false"
      @change_page="changePage"
    >
    </table-component>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import astor from "@/model/astor.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 0, name: "ID" },
          { id: 1, name: "Дата создания" },
          { id: 2, name: "Дата обновления" },
          { id: 3, name: "Название файла" },
          { id: 4, name: "Путь файла" },
          { id: 5, name: "Статус загрузки в БД" },
          { id: 6, name: "Статус загрузки в ElasticSearch" },
          { id: 7, name: "Описание ошибки" },
          { id: 8, name: "Сообщение исключения" },
          { id: 9, name: "Причина срабатывания исключения" },
          { id: 10, name: "updateCategoryProductCount" },
        ],
        body: [],
      },
      pageInput: {
        page: 0,
      },
      pages: {
        count: 0,
        current: 0,
        elements: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      ASTOR: "Astor/STATE",
    }),
  },
  async created() {
    await this.checkPower();
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getFiles: "Astor/GET_FILES_LOGS",
    }),
    checkPower() {
      // TODO: создать права для астора
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        // this.$router.push("/");
      }
    },
    async changePage(val) {
      this.loading = true;
      this.pageInput.page = val;
      await this.setBody();
      this.loading = false;
    },
    async setBody() {
      await this.getFiles(this.pageInput);
      let model = new astor();
      this.items.body = model.setBody(this.ASTOR.files.content, "");
      this.pages = model.setPages(this.ASTOR.files);
    },
  },
};
</script>
