export default class test {
  constructor() {
    this.input = {};
    this.body = [];
  }

  setInput(data) {
    if (data) {
      this.input.search = data.search;
    }
    return this.input;
  }

  setBody(data, bodyType) {
    if (data) {
      let fields = [];
      for (let i = 0; i < data.length; i++) {
        switch (bodyType) {
          case "sku":
            fields = [
              { id: 1, name: data[i].id, type: "string" },
              { id: 2, name: data[i].guid, type: "string" },
              { id: 3, name: data[i].price, type: "string" },
              { id: 4, name: data[i].balance, type: "string" },
              { id: 5, name: data[i].measureId, type: "string" },
              { id: 6, name: data[i].fileName, type: "string" },
              { id: 7, name: data[i].timeOfChange, type: "string" },
              { id: 8, name: data[i].departmentId, type: "string" },
              { id: 9, name: data[i].sale, type: "string" },
            ];
            break;
          case "product":
            fields = [
              { id: 1, name: data[i].id, type: "string" },
              { id: 2, name: data[i].product_id, type: "string" },
              { id: 3, name: data[i].categories, type: "string" },
              { id: 4, name: data[i].guid, type: "string" },
              { id: 5, name: data[i].barcode, type: "string" },
              { id: 6, name: data[i].name, type: "string" },
              { id: 7, name: data[i].description, type: "string" },
              { id: 8, name: data[i].measureId, type: "string" },
              { id: 9, name: data[i].imageUrl, type: "string" },
              { id: 10, name: data[i].fileName, type: "string" },
              { id: 11, name: data[i].timeOfChange, type: "string" },
            ];
            break;
          default:
            fields = [
              { id: 1, name: data[i].id, type: "string" },
              { id: 2, name: data[i].createdAt, type: "string" },
              { id: 3, name: data[i].updatedAt, type: "string" },
              { id: 4, name: data[i].filename, type: "string" },
              { id: 5, name: data[i].filePath, type: "string" },
              { id: 6, name: data[i].databaseUploadStatus, type: "string" },
              {
                id: 7,
                name: data[i].elasticsearchUploadStatus,
                type: "string",
              },
              { id: 8, name: data[i].errorDescription, type: "string" },
              { id: 9, name: data[i].exceptionMessage, type: "string" },
              { id: 10, name: data[i].exceptionCause, type: "string" },
              {
                id: 11,
                name: data[i].updateCategoryProductCount,
                type: "string",
              },
            ];
        }
        this.body.push({ id: i + 1, fields });
      }
    }
    return this.body;
  }

  setPages(data) {
    if (data) {
      this.pages = {
        count: data.totalPages - 1,
        current: data.pageable.pageNumber,
        elements: data.totalElements,
      };
    }
    return this.pages;
  }
}
